import useAuth, { User } from '@/auth'
import useMemberRole from '@/auth/useMemberRole'
import envStore from '@/env'
import { ISimpleModelHistoryState } from '@/routes/history'
import { concat, keys, or } from 'ramda'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const useSimpleMode = () => {
  const { state: locationState } = useLocation<
    ISimpleModelHistoryState | undefined
  >()

  const { OrganizationManagerRoleId } = useMemberRole()

  const history = useHistory()

  const { user } = useAuth()

  const isUsedSimpleModeByUser = React.useCallback((user?: User) => {
    // 系統簡易畫面未開啟, 任何角色都不會有簡易畫面
    if (!envStore.simpleModeSupported) {
      return false
    }
    if (!user) {
      return false
    }

    // 由公司設定 simpleScreenEnabled 開啟, 角色 L5, L6, L7 進入簡易畫面
    if (user?.companyObj?.simpleScreenEnabled) {
      return user.memberRoleId >= OrganizationManagerRoleId
    }
    // 依照原有的規則 simpleScreenEnabled 未設定,  L5, L6, L7 可進入簡易畫面
    return user.memberRoleId >= OrganizationManagerRoleId
  }, [])

  const isUsedSimpleMode = React.useMemo<boolean>(
    () =>
      or(envStore.simpleModeSupported, user?.companyObj?.simpleScreenEnabled)
        ? user?.memberRoleId >= OrganizationManagerRoleId
        : false,
    [
      user?.memberRoleId,
      user?.companyObj?.simpleScreenEnabled,
      envStore.simpleModeSupported,
    ]
  )

  const state = React.useMemo<{
    isAddedMode?: boolean
    actSimpleFillinData: Record<string, any> | undefined
    hiddenValues: string[]
    specialPayload?: ISimpleModelHistoryState['specialPayload']
    actType?: number
    actSimpleFillinId?: number
  }>(
    () => ({
      isAddedMode: locationState?.isAddedMode,
      actSimpleFillinData: locationState?.actSimpleFillinData as
        | Record<string, any>
        | undefined,
      hiddenValues: concat(keys(locationState?.actSimpleFillinData), [
        'originAmountRow',
        'outerLink',
      ]),
      specialPayload: locationState?.specialPayload,
      actType: locationState?.actType,
      actSimpleFillinId: locationState?.actSimpleFillinId,
    }),
    [
      locationState?.actSimpleFillinData,
      locationState?.isAddedMode,
      locationState?.specialPayload,
    ]
  )

  const clearStateIfUsed = React.useCallback(() => {
    history.replace({})
  }, [])

  return {
    isUsedSimpleMode,
    state,
    clearStateIfUsed,
    isUsedSimpleModeByUser,
  }
}

export default useSimpleMode
