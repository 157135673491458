import { useActSimpleFillin } from '@lib/act'
import { useHistory } from 'react-router-dom'
import React from 'react'
import useCompanyUrl from '@lib/hooks/useCompanyUrl'
import { ACTIONS_ROUTES, STATIONARY_COMBUSTION_MANAGEMENT } from '@lib/routes'
import { ISimpleModelHistoryState } from '@lib/routes/history'
import { Button, Col, Row } from 'antd'
import { compose, filter, groupBy, path, toPairs } from 'ramda'
import { useTranslation } from 'react-i18next'
import { Layout, Head, EntranceFlex, RouteDiv, RoutesGrid } from './style'

type IEntranceRoutes = {
  actSimpleFillinId: number
  title: string
  url: string
  imageUrl: string
  actCategoryTitle: string
  specialPayload?: ISimpleModelHistoryState['specialPayload']
  frequency?: string
}

/**
 * GAS: 桶裝瓦斯
 * NATURALGAS: 天然氣
 * SEPTICTANK:化糞池
 * POWERINPUT:輸入電力
 */
export const ActSimpleFillinEnum = {
  GAS: 3,
  NATURALGAS: 4,
  POWERINPUT: 5,
  SEPTICTANK: 8,
}
/**
 * actSimpleFillin mapping actTypeId
 */
export const useEntranceRoutes = () => {
  const { t } = useTranslation()

  const Category1 = t('menu:category1')
  const Category2 = t('menu:category2')
  const Category3 = t('menu:category4')

  return {
    EntranceRoutes: [
      {
        actSimpleFillinId: 1,
        title: t('menu:officialCar'),
        url: '',
        imageUrl: '/img/simpleMode/officialCar.webp',
        actCategoryTitle: Category1,
        frequency: t('common:applyEveryMonth'),
      },
      {
        actSimpleFillinId: 2,
        title: t('menu:dynamo'),
        url: '',
        imageUrl: '/img/simpleMode/dynamo.webp',
        actCategoryTitle: Category1,
      },
      {
        actSimpleFillinId: ActSimpleFillinEnum.GAS,
        title: t('menu:gas'),
        url: '',
        imageUrl: '/img/simpleMode/gas.webp',
        actCategoryTitle: Category1,
      },
      {
        actSimpleFillinId: ActSimpleFillinEnum.NATURALGAS,
        title: t('menu:naturalGas'),
        url: '',
        imageUrl: '/img/simpleMode/natural_gas.webp',
        actCategoryTitle: Category1,
      },
      {
        actSimpleFillinId: ActSimpleFillinEnum.POWERINPUT,
        title: t('menu:electricity'),
        url: '',
        imageUrl: '/img/simpleMode/electricity.webp',
        actCategoryTitle: Category2,
        frequency: t('common:applyEveryMonth'),
      },
      {
        actSimpleFillinId: 6,
        title: t('menu:fireExtinguisher'),
        url: '',
        imageUrl: '/img/simpleMode/fire_extinguisher.webp',
        actCategoryTitle: Category1,
      },
      {
        actSimpleFillinId: 7,
        title: t('menu:refrigerant'),
        url: '',
        imageUrl: '/img/simpleMode/refrigerant.webp',
        actCategoryTitle: Category1,
        specialPayload: {
          fillinType: 'refrigerant',
        },
      },
      {
        actSimpleFillinId: 9,
        title: t('menu:waste'),
        url: '',
        imageUrl: '/img/simpleMode/waste.webp',
        actCategoryTitle: Category3,
        frequency: t('common:applyEveryMonth'),
      },
      {
        actSimpleFillinId: ActSimpleFillinEnum.SEPTICTANK,
        title: t('menu:septicTank'),
        url: '',
        imageUrl: '/img/simpleMode/septic_tank.webp',
        actCategoryTitle: Category1,
      },
      {
        actSimpleFillinId: 10,
        title: t('menu:waterUsage'),
        url: '',
        imageUrl: '/img/simpleMode/water_usage.webp',
        actCategoryTitle: Category3,
        frequency: t('common:applyEveryMonth'),
      },
    ] as IEntranceRoutes[],
    Category1,
  }
}

const Entrance = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory<ISimpleModelHistoryState>()

  const companyUrl = useCompanyUrl()

  const { EntranceRoutes, Category1 } = useEntranceRoutes()

  const [actSimpleFillinId, setActSimpleFillin] = React.useState<
    undefined | number
  >(undefined)

  const { data } = useActSimpleFillin({ actSimpleFillin: actSimpleFillinId })

  React.useEffect(() => {
    if (!data) {
      return
    }
    const specialPayload = compose<
      any,
      any,
      ISimpleModelHistoryState['specialPayload'] | undefined
    >(
      path([0, 'specialPayload']),
      filter((r: IEntranceRoutes) => r.actSimpleFillinId === actSimpleFillinId)
    )(EntranceRoutes)

    history.push({
      pathname: companyUrl.urlReplacer(ACTIONS_ROUTES[data.actType]),
      search: `?actType=${data.actType}&actSimpleFillinId=${actSimpleFillinId}`,
      state: {
        isAddedMode: true,
        actSimpleFillinData: data,
        specialPayload,
        actType: data.actType,
        actSimpleFillinId,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, actSimpleFillinId])

  return (
    <Layout>
      <Head>{t('common:simpleModeTitle')}</Head>
      <div
        style={{
          width: '90%',
          margin: '0 auto',
          padding: '30px 0',
          maxWidth: '1320px',
        }}>
        <Row justify="end">
          <Col>
            <Button
              onClick={() =>
                history.push(
                  companyUrl.urlReplacer(STATIONARY_COMBUSTION_MANAGEMENT)
                )
              }>
              {t('common:appEntrance')}
            </Button>
          </Col>
        </Row>
        <EntranceFlex>
          <div className="w-1/2">
            <h3 className="text-xl font-bold">{Category1}</h3>
            <RoutesGrid>
              {EntranceRoutes.filter(r => r.actCategoryTitle === Category1).map(
                route => (
                  <RouteDiv
                    key={route.title}
                    imageUrl={route.imageUrl}
                    onClick={() => setActSimpleFillin(route.actSimpleFillinId)}>
                    <div className="frequency">{route.frequency}</div>
                    <div className={`title ${i18n.language}`}>
                      {route.title}
                    </div>
                  </RouteDiv>
                )
              )}
            </RoutesGrid>
          </div>
          <div style={{ width: '2px' }} className="bg-darkGrey" />
          <div>
            {compose<any, any, any, [string, IEntranceRoutes[]][]>(
              toPairs,
              groupBy((r: IEntranceRoutes) => r.actCategoryTitle),
              filter((r: IEntranceRoutes) => r.actCategoryTitle !== Category1)
            )(EntranceRoutes).map(([actCategory, routes]) => {
              return (
                <div style={{ padding: '15px 30px' }} key={actCategory}>
                  <h3 className="text-xl font-bold">{actCategory}</h3>
                  <RoutesGrid>
                    {routes.map(entranceRoute => (
                      <RouteDiv
                        key={entranceRoute.title}
                        imageUrl={entranceRoute.imageUrl}
                        onClick={() =>
                          setActSimpleFillin(entranceRoute.actSimpleFillinId)
                        }>
                        <div className="frequency">
                          {entranceRoute.frequency}
                        </div>
                        <div className={`title ${i18n.language}`}>
                          {entranceRoute.title}
                        </div>
                      </RouteDiv>
                    ))}
                  </RoutesGrid>
                </div>
              )
            })}
          </div>
        </EntranceFlex>
      </div>
    </Layout>
  )
}

export default Entrance
